import React from 'react'
import img from '../../assets/Rectangle 75 (1).png';

function TestimonialA() {
    return (
        <div className='w-full h-full m-0 p-0 flex justify-center items-center'>

            <div className=' w-5/6 h-4/6 rounded-3xl mt-20 max-md:mt-20 mb-3 max-[770px]:mt-10 max-w-screen-2xl max-h-screen-lg  flex justify-center items-center  bg-[#E3E9FF]'>

                <div className='content   w-[40%] max-md:w-[50%] h-full max-sm:w-full max-w-xl flex items-center justify-start max-lg:ml-2 '>
                    <div className='flex  flex-col  p-2  w-5/6 max-sm:w-full h-5/6  ml-7    max-sm:items-center max-sm:ml-0 '>
                        {/* <div className='text-5xl max-sm:text-lg  flex flex-col max-sm:flex-row max-[440px]:text-sm max-lg:mt-3  ml-5 max-sm:ml-0  max-[981px]:gap-2 max-[770px]:gap-1 gap-4 max-xl:text-4xl max-[770px]:text-2xl max-[981px]:text-3xl  font-bold  text-white'><span>Cross the Horizon with</span> <span> MCT Chromehorizon</span></div> */}
                        <h1 className='text-3xl max-lg:text-xl font-bold text-black max-md:mt-3'>Testimonials</h1>
                        <p className='max-sm:text-center max-sm:ml- text-lg max-lg:text-sm  font-thin mt-4 max-md:mt-2'>Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy.</p>

                        <button type='submit' className='bg-yellow-300 w-32 max-lg:w-24 font-semibold text-sm  max-sm:ml-0 text-center rounded-md p-1 mt-5 max-md:mt-2 max-[770px]:mt-6 max-sm:mb-5 max-lg:text-xs  '>Get in Touch</button>
                    </div>
                </div>
                <div className='image  w-[60%] max-md:w-[50%] flex justify-center items-center  max-[981px]:w-4/5 h-full  max-sm:hidden  p-2'>
                    <img src={img} alt='#' className='rounded-2xl w-full h-full  object-cover ' />
                </div>

            </div>

        </div>
    )
}

export default TestimonialA
