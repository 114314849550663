import React, { useState } from "react";
import LearningCultureA from "./LearningCultureA";
import LearningCultureB from "./LearningCultureB";
import BlogData from "../blogs.json";

function LearningCultureBlog() {
  const [data, setData] = useState(BlogData);
  return (
    <div>
      <LearningCultureA data={data} />
      <LearningCultureB data={data} />
      {/* <LearningCultureC data={data}/> */}
    </div>
  );
}

export default LearningCultureBlog;
