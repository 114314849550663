import React from 'react'
import pic2 from '../../assets/img_lms2.png'

function HomeCompoB({data}) {
  
  return (
    <div >
      <div className='w-full h-full m-0 p-0 flex items-center justify-center' >
        <div className='w-5/6 h-5/6 max-w-screen-xl max-h-screen-lg max-sm:w-full flex justify-center max-sm:p-3 max-[940px]:p-3 max-sm:items-center items-center'>
          <div className='content w-[60%] max-md:w-full ml-4  h-full'>
            <p className='text-black font-bold text-2xl max-[1120px]:text-xl  max-[950px]:text-lg max-sm:text-sm '>{data.title}</p>
            <p className='text-lg  text-[#3D52A0] max-[875px]:mt-3  max-[1080px]:text-sm max-sm:text-xs mt-5 text-justify'>{data.subDescription}</p>
          </div>
          <div className='image w-[40%] h-full p-4 max-md:hidden flex justify-center items-center '>
            <div className='image w-full h-full  '>
              <img src={pic2} alt='pic ' className='w-full h-full object-cover' />
            </div>


          </div>
        </div>
      </div>

    </div>
  )
}

export default HomeCompoB
