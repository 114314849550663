import React from 'react'
import imgA from '../../assets/HomeCarausel1.jpg'
import imgB from '../../assets/HomeCarousel2.jpg'
import imgC from '../../assets/HomeCarousel3.jpg'
import Slider from 'react-slick'
import { NavLink } from 'react-router-dom'

function HomeCompoA({ data }) {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    <div className='w-full h-full flex flex-col justify-center bg-black items-center overflow-hidden'>
      <div className='w-full h-1/2 mt-16 max-h-screen  max-w-screen-2xl relative opacity-50'>
        <Slider {...settings} >
          <div className='card1 w-full h-full'>

            <div className='w-full h-full '>
              <img src={imgA} alt='' className='w-full h-full object-cover ' />

            </div>
          </div>
          <div className='card2 w-full'>

            <div className='w-full h-full'>
              <img src={imgB} alt='' className='w-full h-full object-cover ' />

            </div>
          </div>
          <div className='card3 w-full'>

            <div className='w-full h-full'>
              <img src={imgC} alt='' className='w-full h-full object-cover' />

            </div>
          </div>


        </Slider>
      </div>
      <div className='w-5/6  h-full max-[430px]:mt-10   gap-5  absolute flex flex-col justify-center items-center'>

        <div className='flex max-md:w-full  justify-start max-md:justify-center items-center'>
          <p className=' text-6xl max-lg:text-5xl  max-[1092px]:text-5xl text-white max-md:text-3xl max-sm:text-2xl max-[450px]:text-xl max-[388px]:mt-4  text-center font-semibold '>{data.titleA}<br />{data.titleB}</p>
        </div>

        <div className='w-3/6 max-md:w-full max-md:p-3  flex  mb-5 justify-center items-center'>
          <div className='w-32 h-12 max-sm:h-10 max-[450px]:h-7 p-2 flex justify-center items-center bg-[#F93E5E] hover:bg-blue-700 rounded-full'>
           <NavLink to='/contact' > <p className='text-center text-white text-sm font-bold'>{data.button} </p></NavLink>

          </div>


        </div>
      </div>
    </div>
  )
}

export default HomeCompoA
