import React, { useState } from 'react'
import AboutA from './AboutA'
import AboutB from './AboutB'
import AboutC from './AboutC'
import Data from './AboutUs.json'

function About() {
  const [data, setData] = useState(Data);
  console.log(data)
  return (
    <div className='bg-indigo-100'>

        <AboutA  data={data}/>
        <AboutB data={data}/>
        <AboutC data={data}/>
      
    </div>
  )
}

export default About
