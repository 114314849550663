import React, { children, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
// import Sidebar from './Sidebar'



const Layout = ({ children }) => {
      
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
      
    return (
        <>
         <div className=' w-full fixed z-40'><Header /></div>
         <main>  
            {children}
         </main>

         <div className='w-full '><Footer /></div> 
        </>
    )
}

export default Layout