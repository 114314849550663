import React from 'react';
import { ImCross } from 'react-icons/im';
import { NavLink } from 'react-router-dom';

function Sidebar({ onClose }) {
  return (
    <div className='fixed left-0 top-0 w-1/2 h-screen bg-slate-400 rounded-xl flex flex-col items-center  gap-10 font-semibold text-lg  transform transition-transform translate-x-0 ease-in-out duration-300 '>
      <ImCross
        className='text-2xl text-white cursor-pointer absolute top-4 right-4'
        onClick={onClose}
      />
      <span className='w-full mt-10 text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all  cursor-pointer'>
      <NavLink to='/'> Home </NavLink>
      </span>
      {/* <span className='w-full text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer'>
       <NavLink to='/curriculam'> Curriculam </NavLink>
      </span> */}
      <span className='w-full text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer'>
       <NavLink to='/blogHome'> Blog </NavLink>
      </span>
      <span className='w-full text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer'>
      <NavLink to='/about'> About Us</NavLink>
      </span>
      <span className='w-full text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer'>
      <NavLink to='/contact'> Contact Us</NavLink>
      </span>
      
      {/* <span className='w-full text-black text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer'>
        Start Learning
      </span> */}
    </div>
  );
}

export default Sidebar;

