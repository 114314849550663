import React from 'react'
import img from '../../assets/image.png';
import { FaQuestion } from "react-icons/fa";

function HomeCompoC({ data }) {
  return (
    <div>
      <div className='w-full h-full flex  justify-center items-center  text-white  bg-[#3D52A0]'>

        <div className='w-5/6 h-5/6 max-md:w-full  max-w-screen-xl max-h-screen-lg flex justify-center items-center'>

          <div className='content w-[50%] max-md:w-full  h-full max-[1256px]:p-7   '>
            <div className='text-2xl max-sm:text-2xl font-bold text-white '>{data.heading}</div>
            <div className='w-full h-full max-lg:text-sm  font-thin'>
              <ul className='text-sm max-md:text-xs ml-3 mt-3 max-sm:mt-1 list-disc text-justify'>
                {data.list && data.list.map((val, index) => (
                  <li key={index}>
                    {val.title}<br />
                    {val.description}
                  </li>
                ))}
              </ul>
            </div>

          </div>

          <div className='image w-[50%] h-full  max-md:hidden  flex justify-center items-center  '>
            <div className='w-5/6  mt-10 relative  '>

              <img src={img} alt='pic' className='w-full max-w-full object-cover relative z-20  h-full  rounded-lg' />
              <div className="absolute h-32 right-0 bottom-[15%] overflow-hidden w-2/3 flex justify-between">
                <div className='w-[30%] h-[50%] absolute bottom-0 left-[10%] bg-gray-400 z-10 rounded-[50%] opacity-40 '></div>
                <div className='w-[15%] h-[30%] absolute bottom-0 left-[50%] bg-gray-400 z-10 rounded-[50%] opacity-40'></div>
                <div className='w-[25%] h-[55%] absolute bottom-[20%] right-[5%] bg-blue-400 z-10 rounded-[50%] opacity-40'></div>
                
              </div>


            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCompoC
