import React from 'react'
import blog1 from '../../assets/Blog1f.jpg'
import blog2 from '../../assets/Blog2f.jpg'
import { NavLink } from 'react-router-dom'
import blog3 from '../../assets/Blog3f.jpg'

function BlogHome() {
    return (
        <div className='w-full h-full flex  justify-center items-center'>
            <div className='w-[70%] max-md:w-full h-full flex flex-col gap-8 p-8 mt-14 justify-center items-center'>
                <div className='w-full h-full flex flex-col gap-8 max-md:gap-5 justify-start items-start'>
                    <p className='text-4xl max-lg:text-2xl max-md:text-xl max-sm:text-lg text-start font-bold'> Blogs</p>
                    <p className='text-lg max-[1060px]:text-sm max-sm:text-xs text-start '> Welcome to the MCT LMS Blog – Your Gateway to Learning Insights
                        Explore expert insights, tips, and trends in the world of Learning Management Systems (LMS). Our blog is your go-to resource for understanding how technology is transforming education and corporate training.</p>

                </div>

                <div className='w-full h-full gap-4 border-2 p-2  bg-slate-400 rounded-lg flex max-md:flex-col  justify-center items-center'>
                    <div className='w-[50%] max-md:w-5/6 max-sm:w-full h-full flex justify-center items-center'>
                        <img src={blog1} alt='' className='object-scale-down max-h-full  drop-shadow-md rounded-md m-auto' />
                    </div>
                    <div className='w-[50%] max-md:w-full h-full flex flex-col gap-3 max-[852px]:gap-1 max-md:gap-2 justify-center items-center'>
                        <p className='w-full max-md:w-5/6 max-sm:w-full text-xl  max-[1225px]:text-lg max-[1025px]:text-sm max-md:text-lg max-sm:text-sm font-semibold  '>The Future of Learning – How LMS is Shaping Education</p>

                        <p className='w-full max-md:w-5/6 max-sm:w-full text-base max-[1225px]:text-sm max-[1025px]:text-xs max-md:text-sm  max-sm:text-xs text-justify'>As technology continues to evolve, the world of education and corporate training is undergoing a major transformation.</p>

                        <div className='w-full h-full flex justify-center items-center'>
                            <NavLink to='/future-of-learning' >  <button type='submit' className='flex justify-center items-center text-center text-xs text-white w-20 h-6   bg-blue-900 hover:bg-black  rounded-md font-semibold  py-2 ' /*onClick={() => setCollapse(prev => prev === 1 ? null : 1)}*/ >Read More</button></NavLink>
                        </div>

                    </div>

                </div>


                <div className='w-full h-full gap-4 border-2 p-2 bg-slate-400 rounded-lg flex max-md:flex-col justify-center items-center'>
                    <div className='w-[50%] max-md:w-5/6 max-sm:w-full h-full flex justify-center items-center'>
                        <img src={blog2} alt='' className=' object-scale-down max-h-full  drop-shadow-md rounded-md m-auto' />
                    </div>
                    <div className='w-[50%] max-md:w-full h-full flex flex-col gap-3 max-[852px]:gap-1 max-md:gap-2 justify-center items-center'>
                        <p className='w-full max-md:w-5/6 max-sm:w-full text-xl  max-[1225px]:text-lg max-[1025px]:text-sm max-md:text-lg max-sm:text-sm font-semibold  '>Ways to Boost Employee Engagement with an LMS</p>

                        <p className='w-full max-md:w-5/6 max-sm:w-full text-base max-[1225px]:text-sm max-[1025px]:text-xs max-md:text-sm  max-sm:text-xs text-justify'>In today’s fast-paced business world, keeping employees engaged in learning and development programs can be challenging.</p>

                        <div className='w-full h-full flex justify-center items-center'>
                            <NavLink to='/waystoBoost' >  <button type='submit' className='flex justify-center items-center text-center text-xs text-white w-20 h-6   bg-blue-900 hover:bg-black  rounded-md font-semibold  py-2 ' /*onClick={() => setCollapse(prev => prev === 1 ? null : 1)}*/ >Read More</button></NavLink>
                        </div>

                    </div>

                </div>


                <div className='w-full h-full gap-4 border-2 p-2 bg-slate-400 rounded-lg flex max-md:flex-col justify-center items-center'>
                    <div className='w-[50%] max-md:w-5/6 max-sm:w-full h-full flex justify-center items-center'>
                        <img src={blog3} alt='' className='object-scale-down max-h-full drop-shadow-md rounded-md m-auto' />
                    </div>
                    <div className='w-[50%] max-md:w-full h-full flex flex-col gap-3 max-[852px]:gap-1 max-md:gap-2 justify-center items-center'>
                        <p className='w-full max-md:w-5/6 max-sm:w-full text-xl  max-[1225px]:text-lg max-[1025px]:text-sm max-md:text-lg max-sm:text-sm font-semibold '>Building a Continuous Learning Culture with an LMS</p>

                        <p className='w-full max-md:w-5/6 max-sm:w-full text-base max-[1225px]:text-sm max-[1025px]:text-xs max-md:text-sm  max-sm:text-xs text-justify'>In today’s fast-evolving market, a continuous learning culture is essential for business growth and employee satisfaction.</p>

                        <div className='w-full h-full flex justify-center items-center'>
                            <NavLink to='/learningculture'>  <button type='submit' className='flex justify-center items-center text-center text-xs text-white w-20 h-6   bg-blue-900 hover:bg-black  rounded-md font-semibold  py-2 ' /*onClick={() => setCollapse(prev => prev === 1 ? null : 1)}*/ >Read More</button></NavLink>
                        </div>

                    </div>

                </div>

            </div>
            <div className='w-[30%] h-full gap-8 max-[1060px]:gap-6 max-[992px]:gap-5 max-md:hidden flex flex-col justify-start items-center'>
                <p className='w-  text-2xl max-lg:text-xl max-md:text-lg max-sm:text-sm mt-32 underline font-bold text-black'>Categories</p>
                
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/' >  <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>E-Learning Platform</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/learningculture' >  <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Online Learning</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/waystoBoost' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Corporate Training Software</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/' >  <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Cloud-Based LMS</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/future-of-learning' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>LMS for Education</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/waystoBoost' >  <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Employee Training System</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/future-of-learning' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Mobile Learning</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/learningculture' >  <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Digital Learning Tools</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>SCORM-Compliant LMS</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/waystoBoost' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Gamified Learning</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/about' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>LMS for Small Businesses</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500'>
                    <NavLink to='/learningculture' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Virtual Classroom Software</p></NavLink>
                </div>
                <div className='w-[80%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-slate-500 '>
                    <NavLink to='/waystoBoost' > <p className='text-lg max-[1060px]:text-sm cursor-pointer font-semibold text-black'>Blended Learning Solutions</p></NavLink>
                </div>

            </div>

        </div>
    )
}

export default BlogHome
