import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import img from '../../assets/map.jpeg'


const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  subject: Yup.string().required('Subject is required'),
  QuerryBox: Yup.string().required('Some Suggestions or Querry required'),
});

function HomeCompoI() {

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      QuerryBox: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });


  return (
    <div>
      <div className='w-full h-full m-0 p-0 flex justify-center items-center'>

        <div className='main w-full h-full bg-slate-900 relative flex justify-center items-center  bg-center bg-no-repeat bg-cover bg-opacity-40'
          style={{ backgroundImage: "url(" + img + ")" }}
          id='image'>



          <div className='w-4/5 h-4/5  max-w-4xl m-10  max-md:w-full max-sm:h-4/6 max-md:h-full flex flex-col border-2 border-black rounded-lg justify-center items-center  bg-[#8EA4F5]'>



            <div className=' text-center mt-24  flex justify-center items-center max-sm:lg:items-center mr-10 w-5/6 flex-col'>
              <p1 className='flex justify-center  max-sm:text-center max-sm:items-center max-sm:text-2xl text-center max-lg:text-2xl font-bold text-3xl   text-white '>Get in Touch</p1>
              <div className='max-md:text-sm max-sm:w-20 max-md:-ml-8 max-sm:h-2 max-sm:border-b-4 max-sm:-ml- border-b-8 border-yellow-500 h-5 w-32 ml-12   relative  left-24'>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className='w-5/6  -mt-10 '>

              <div className='w-full h-4/5 max-sm:h-40 space-x-1 mt-24  max-sm:flex-col flex  justify-between'>
                <div className='mr-36 w-full  h-full flex flex-col'>
                  <label className=" text-white space-y-  text-sm font-bold ">Name
                  </label>
                  <input type='text' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} className="appearance-none border-2  text-md rounded w-full  p-2 y-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Your Name" />
                  {formik.touched.name && formik.errors.name ? (<div className='text-red-500 text-sm'>{formik.errors.name}</div>) : null}
                </div>

                <div className='mr-36 w-full h-full flex flex-col'>
                  <label className=" text-white text-sm font-bold " htmlFor="username">Email
                  </label>
                  <input type='text' name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="appearance-none border-2  text-md rounded w-full  p-2 y-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Your email" />
                  {formik.touched.email && formik.errors.email ? (<div className='text-red-500 text-sm'>{formik.errors.email}</div>) : null}
                </div>
              </div>

              <div className='space-x-1 translate-y-20 max-sm:translate-y-1   flex max-sm:flex-col  justify-between'>
                <div className='mr-36 w-full  h-full flex flex-col'>
                  <h1 className=" text-white text-sm w-full h-full  font-bold ">Phone
                  </h1>
                  <input type='text' name='phone' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone} className=" border-2  text-md space-x-3 rounded w-full h-full p-2 y-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Your phone number" />
                  {formik.touched.phone && formik.errors.phone ? (<div className='text-red-500 text-sm'>{formik.errors.phone}</div>) : null}
                </div>
                <div className='w-full h-full max-sm:mt-3 flex flex-col'>
                  <h1 className=" text-white text-sm font-bold ">Subject
                  </h1>
                  <select name='subject' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} className='appearance-none border-2  text-md rounded w-full h-full p-2 y-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'>
                    <option >Choose your Subject</option>
                    <option >Computer</option>
                    <option >Java</option>
                    <option >Rubi</option>
                    <option>Web Development</option>
                    <option>Python</option>
                    <option>C Languege</option>
                    <option>Other</option>


                  </select>
                  {formik.touched.subject && formik.errors.subject ? (<div className='text-red-500 text-sm'>{formik.errors.subject}</div>) : null}
                </div>

              </div>

              <div className='w-full space-x-1 translate-y-20 max-sm:translate-y-1 mt-12   flex max-sm:flex-col  justify-between'>
                <div className='w-full  h-full flex flex-col'>
                  <h1 className=" text-white text-sm w-full h-full  font-bold ">Have Any Querry ?
                  </h1>
                  <textarea id="Querry" rows="2" cols="50" name='QuerryBox' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.QuerryBox} className=" border-2 w-full resize-none text-md space-x-3 rounded  h-full p-2 y-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Your Querry" ></textarea>
                  {formik.touched.QuerryBox && formik.errors.QuerryBox ? (<div className='text-red-500 text-sm'>{formik.errors.QuerryBox}</div>) : null}
                </div>
              </div>

              <div className='-mt-8 flex justify-center items-center'>
                <div className=' w-full  space-x-5 mt-52 text-justify max-sm:mb-5 max-md:h-full  max-lg:mt-44 max-sm:mt-16  max-md:text-xs flex items-center justify-center   text-bold'>

                  <button type='submit' className=' bg-yellow-400 mb-16 font-semibold flex text-black rounded-lg  px-14 py-3'>Submit</button>
                </div>
              </div>
            </form>


          </div>
        </div>

      </div>
    </div>



  )
}

export default HomeCompoI