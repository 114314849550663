import React from 'react'
import { FaQuestion } from 'react-icons/fa6'

function AboutC({ data }) {
    const title = data && data[2].title;
    const List = data && data[2].List;
    return (
        <div>
            <div className='w-full h-full  flex justify-center overlflow-hidden items-center mx-auto bg-[#3D52A0]'>
                <div className='w-full max-md:w-full max-w-screen-xl max-h-screen-lg  flex h-full mt-40 '>
                    <div className='w-full  h-full p-4  max-lg:ml-   flex flex-col gap-10  align-middle  my-1 mb-4 text-white text-center'>
                        <div className='max-xs:w-full max-xs:h-4/5  flex-justify justify-between'>

                            <p1 className='flex justify-start -mt-36 max-lg:text-lg  text-2xl '>{title} </p1>
                            <div className='max-md:text-sm max-sm:w-20 max-md:-ml-8 max-lg:-ml-10 max-sm:-ml-7 border-b-4 border-yellow-300 w-40 ml-5 mr-96 relative  left-24'>
                            </div>
                            <br />
                            {List.length > 0 && (
                                <ul className=' mt-2 ml-5  max-sm:mt-5'>
                                    {List.map((val, listIndex) => (
                                        <div key={listIndex} className='max-lg:text-sm flex items-center py-3 px-2 max-sm:py-1 space-x-4 text-yellow-500 text-2xl'>
                                            <span className='text-sm'>  < FaQuestion /></span>
                                            <li className=' text-white text-sm max-lg:text-sm text-justify' ><span className='font-bold'>{val.bold} : </span> {val.description}</li>
                                        </div>
                                    ))}
                                    
                                </ul>
                            )}

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default AboutC
