import React, { useState } from 'react'
import img1 from '../../assets/homeCard1.jpeg';
import imgA from '../../assets/home_g_imgA.jpg';
import imgB from '../../assets/home-E.png';
import imgC from '../../assets/pic1.jpeg';
import { BsClock } from "react-icons/bs";
import { NavLink } from 'react-router-dom';



function HomeCompoG({ data }) {
  const [collapse, setCollapse] = useState(false);
  return (
    <div>
      <div className='w-full h-full m-0 p-0 bg-[#3D52A0] flex items-center justify-center  '>
        <div className='w-4/6 max-sm:w-full max-sm:ml-7 mb-10 max-w-4xl max-lg:w h-5/6 flex flex-col  items-center '>
          <div className='text-3xl max-md:text-2xl  text-white font-bold mt-5'><p>Our <span className='border-b-4 border-yellow-500'> Blogs</span></p></div>

          <div className='card w-full mt-8 flex max-lg:overflow-auto scrollbar-hide gap-8 max-sm:gap-3 '>

            {data.card && data.card.map((val) => (
              <div className='card1 w-1/3 max-w-lg max-lg:flex-none  max-lg:w-1/2 max-md:w-5/6 max-md:mx-4 max-lg:h-auto   rounded-lg ml-2 p-2 bg-white'>
                <img src={val.img} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-center' />
                <div className='content mt-3 ml-2'>
                  <h2 className='text-sm max-lg:text-sm font-bold'>{val.title} </h2>
                  <p className='text-sm max-lg:text-xs text-justify mt-2'>{val.description}
                  </p>
                  {/* <hr className='mt-2' /> */}
                </div>
                {/* <div className='w-full h-10 mt-2  flex justify-between'>
                  <div className='flex justify-center items-center gap-1'>
                    <img src={imgA} alt='pic' className='w-10 h-10 object-contain rounded-full' />
                    <p className='text-sm max-lg:text-xs font-semibold'>{val.author}</p>
                  </div>
                  <div className='flex text-xs max-lg:text-xs   gap-1'>
                    <BsClock className='mt-1 text-slate-500 size-5 max-lg:size-4 ' />
                    <div className='text-slate-500'>
                      <p>{val.date}</p>
                      <p>{val.time}</p>
                    </div>
                  </div>

                </div> */}
                <div className='text-center mt-5'>
                  <NavLink to={val.slug}> <button type='submit' className='text-center text-xs  mb-5 text-white w-20  bg-black hover:bg-blue-900  rounded-md font-semibold  py-2 ' /*onClick={() => setCollapse(prev => prev === 1 ? null : 1)}*/ >Read More</button></NavLink>
                </div>



              </div>
            ))}





          </div>

          {/* <div>
            <NavLink to='/blogHome'> <button type='submit' className='text-center text-xs mt-5 mb-10 text-white w-32 max-md:w-20 bg-black  rounded-md font-semibold  py-2 ' >View More</button></NavLink>
          </div> */}

        </div>
      </div>

    </div>
  )
}

export default HomeCompoG
