import React, { useState } from 'react'
import HomeCompoC from './HomeCompoC'
import HomeCompoB from './HomeCompoB'
import HomeCompoD from './HomeCompoD'
import HomeCompoA from './HomeCompoA'
import HomeCompoE from './HomeCompoE'
import HomeCompoF from './HomeCompoF'
import HomeCompoG from './HomeCompoG'
import HomeCompoH from './HomeCompoH'
import HomeCompoI from './HomeCompoI'
import Data from '../home/Home.json'


function Home() {
  const [data, setData] = useState(Data);
  console.log(data);

  return (
    <div className=' bg-[#E3E9FF]'>
    <HomeCompoA data= {data.sectionOne} />
    <HomeCompoB data = {data.sectionTwo}/>
    {/* <HomeCompoC data = {data.sectionThree} /> */}
    <HomeCompoH />
    <HomeCompoD data = {data.sectionFour} />
    <HomeCompoE data = {data.bussinessModel} />
    <HomeCompoF data = {data.ourResearch} />
    <HomeCompoG data = {data.blogData} />
      
    {/* <HomeCompoI data = {data} /> */}
    
    </div>
  )
}

export default Home
