import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { MdWifiCalling3 } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { RiHomeOfficeLine } from "react-icons/ri";

function ContactUs() {
    return (
        <div className='w-full h-full flex  justify-center items-center'>

            <div className='w-full mt-20 h-full bg-slate-400  flex max-md:flex-col justify-center items-center'>

                <div className='w-[50%] max-md:w-5/6 max-[400px]:w-full h-full p-5 mb-10 gap-5 max-[1350px]:gap-3 flex flex-col justify-center items-center'>
                    <div className='w-full h-full flex gap-3 max-[1350px]:gap-3 bg-white p-3 mt-8 max-md:h-5/6 max-md:w-full   flex-col justify-center border-2 rounded-3xl items-center'>
                        <div className='w-full h-full'>
                            <p className='text-2xl max-[1125px]:text-xl font-bold flex justify-center items-center max-md:text-2xl'> Contact Info</p>
                            <p className='w-full  mt-2 text-center italic text-sm max-[1125px]:text-xs max-sm:text-xs'>Reach out to MCT LMS – Your Partner in Seamless Learning Solutions!</p>
                        </div>
                        <div className='w-full flex gap-10 max-[320px]:gap-2  justify-start items-center '>
                            <div className='w-12 h-12 cursor-pointer max-[1250px]:w-10 max-[1250px]:h-10 max-[400px]:w-8 max-[400px]:h-8 rounded-full flex justify-center items-center hover:bg-blue-600 hover:text-white bg-slate-100'>
                                <MdWifiCalling3 className='w-5 h-5 max-[400px]:w-3 max-[400px]:h-3' />
                            </div>
                            <a href="tel:+91-8178803636" className='text-sm cursor-pointer max-sm:text-xs hover:text-blue-600'>
                                Call Us: +91-8178803636
                            </a>
                        </div>
                        <hr className='w-full bg-black' />
                        <div className='w-full flex gap-10 max-[320px]:gap-2  justify-start items-center '>
                            <div className='w-12 h-12 cursor-pointer  max-[1250px]:w-10 max-[1250px]:h-10 max-[400px]:w-8 max-[400px]:h-8  rounded-full flex justify-center items-center hover:bg-blue-600 hover:text-white bg-slate-100'>
                                <MdEmail className='w-5 h-5 max-[400px]:w-3 max-[400px]:h-3 ' />
                            </div>
                            <a href="mailto:fly@maplecloudtechnologies.com" className='text-sm cursor-pointer max-sm:text-xs hover:text-blue-600'>
                                fly@maplecloudtechnologies.com
                            </a>

                        </div>
                        <hr className='w-full bg-black' />
                        <div className='w-full flex gap-10 max-[320px]:gap-2 mb-3 justify-start items-center '>
                            <div className='w-12 h-12 cursor-pointer max-[1250px]:w-10  max-[550px]:ml-0 max-[1250px]:h-10 max-[400px]:w-8 max-[400px]:h-8 rounded-full flex  justify-center items-center hover:bg-blue-600 hover:text-white bg-slate-100'>
                                <FaLocationDot className='w-5 h-5 max-[400px]:w-3 max-[400px]:h-3' />
                            </div>
                            <p className='text-sm cursor-pointer max-sm:text-xs hover:text-blue-600'>
                                E-29, 3rd Floor, Sector 63, Noida,UP 201301 </p>
                        </div>
                        <div className='w-full flex gap-10 max-[320px]:gap-2 mb-3  justify-start items-center '>
                            <div className='w-12 h-12 max-[1250px]:w-10 cursor-pointer max-[550px]:ml-0 max-[1250px]:h-10 max-[400px]:w-8 max-[400px]:h-8 rounded-full flex  justify-center items-center hover:bg-blue-600 hover:text-white bg-slate-100'>
                            <a href='https://www.maplecloudtechnologies.com/' target='_blank' rel='noopener noreferrer'> <RiHomeOfficeLine className='w-5 h-5 object-fill   max-[400px]:w-3 max-[400px]:h-3' /></a>
                            </div>
                            <a href='https://www.maplecloudtechnologies.com/' target='_blank' rel='noopener noreferrer'>
                                <p className='text-sm cursor-pointer max-sm:text-xs hover:text-blue-600'>
                                    Visit Our Company Website
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='w-full md:w-5/6 max-md:w-5/6 max-sm:p-3 max-[400px]:w-full pr-3 max-md:pr-0 max-md:p-1  h-5/6 flex justify-center items-center overflow-hidden'>
                    <div className='w-full p-2 flex justify-center items-center h-full rounded-xl overflow-hidden pb-[40%] max-[1445px]:pb-[43%] max-[1365px]:pb-[45%] max-[1290px]:pb-[50%] max-md:pb-[45%] max-[620px]:pb-[60%] max-[500px]:pb-[70%] max-[1150px]:pb-[52%] max-[1032px]:pb-[57%]  max-[978px]:pb-[60%] max-[925px]:pb-[65%] max-[885px]:pb-[70%] max-[850px]:pb-[75%] max-[815px]:pb-[80%] max-[790px]:pb-[85%] max-[776px]:pb-[90%] relative'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2926032066785!2d77.3832408750079!3d28.620991284605598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce588f185f0d1%3A0x1ec6a8a0233ded52!2sMapleCloud%20Technologies!5e0!3m2!1sen!2sin!4v1728541716110!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            title='map'
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className='w-full h-full border-0 absolute left-0 top-0'
                        ></iframe>
                    </div>
                </div>

            </div>
        </div>




    )
}

export default ContactUs
