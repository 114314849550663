import React from 'react'
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
// import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import pic from '../assets/MAPLE.svg';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className='w-full h-full m-0 p-0 bg-black'>
        <div className='w-full h-full flex flex-col text-white gap-10 justify-center items-center'>

          <img src={pic} alt='' className='w-80 h-16 mt-2 object-cover' />

          <div className=' flex  max-sm:gap-5 text-lg gap-x-14 max-sm:gap-x-10  max-sm:text-xs hover:cursor-pointer '>
            <NavLink to='/'><p className='hover:underline hover:text-blue-500'>Home</p></NavLink>
            {/* <NavLink to='/curriculam'> <p className='hover:underline hover:text-blue-500'>Curriculum</p></NavLink> */}
            {/* <NavLink to='/testimonial'> <p className='hover:underline hover:text-blue-500'>Testimonial</p></NavLink> */}
            <NavLink to='/blogHome'> <p className='hover:underline hover:text-blue-500'>Blog</p></NavLink>
            <NavLink to='/about'><p className='hover:underline hover:text-blue-500'>About Us</p></NavLink>
            <NavLink to='/contact'> <p className='hover:underline hover:text-blue-500'>   Contact Us </p></NavLink>

          </div>

          <div className=' flex gap-10 '>
            {/* <NavLink to=''> <FaFacebook className='size-10 max-sm:size-6 hover:text-blue-500 hover:cursor-pointer' /></NavLink> */}
            <a href='https://www.instagram.com/maplecloudtech?igsh=cGdkZHI5ZTUwbXAy' target='_blank' rel='noopener noreferrer'><FaInstagram className='size-10 max-sm:size-6 hover:text-blue-500 hover:cursor-pointer' /></a>
            <a href='https://www.youtube.com/@MapleCloudTechnologies' target='_blank' rel='noopener noreferrer'><FaYoutube className='size-10 max-sm:size-6 hover:text-blue-500 hover:cursor-pointer' /></a>
            <a href='https://in.linkedin.com/company/maplecloudtechnologies?trk=similar-pages' target='_blank' rel='noopener noreferrer'> <FaLinkedin className='size-10 max-sm:size-6 hover:text-blue-500 hover:cursor-pointer' /></a>

          </div>
          <div className='text-xs mb-1 max-sm:mb-2'>MapleCloud Technologies ©2024. All Right Reserved</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
