
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home'
import Layout from './components/Layout';
import About from './pages/aboutUs/About';
import Testimonial from './pages/testimonial/Testimonial';
import Curriculam from './pages/curriculam/Curriculam';
import WaysToBoostEmpBlog from './pages/blogs/waysToBoostEmpBlog/WaysToBoostEmpBlog';
import LearningCultureBlog from './pages/blogs/learningCultureBlog/LearningCultureBlog';
import FutureOfLearningBlog from './pages/blogs/futureOfLearningBlog/FutureOfLearningBlog';
import { useEffect } from 'react';
import BlogHome from './pages/blogs/BlogHome';
import ContactUs from './pages/contactUs/ContactUs';




function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <BrowserRouter>
    <div className="App">
      <Layout>
      <Routes>
        <Route path='/' element={ 
          <Home/>
         }/>
          <Route path='/about' element={ 
          <About/>
         }/>
         <Route path='/testimonial' element={ 
          <Testimonial />
         }/>
         <Route path='/curriculam' element={ 
          <Curriculam />
         }/>
         <Route path='/contact' element={ 
          <ContactUs />
         }/>
         <Route path='/blogHome' element={ 
          <BlogHome />
         }/>
         <Route path='/waystoBoost' element={ 
          <WaysToBoostEmpBlog />
         }/>
         <Route path='/learningculture' element={ 
          <LearningCultureBlog />
         }/>
         <Route path='/future-of-learning' element={ 
          <FutureOfLearningBlog />
         }/>
      </Routes>
         </Layout>
        </div>
    </BrowserRouter>
  );
}

export default App;
