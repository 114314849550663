import React, { useState } from 'react'
import imgA from '../../../assets/learningCult.png'
import imgB from '../../../assets/homeCard1.jpeg'
import { NavLink } from 'react-router-dom';

function BoostEmpBlogB({ data }) {
  const blogdata = data && data[1].blogs;
  const descriptionData = data && data[1].description;
  const content = data && data[1].content;

  const [collapse, setCollapse] = useState(false);
  return (
    <div>
      <div className='w-full h-full flex justify-center m-0  items-center bg-[#E3E9FF]'>
        <div className='w-full h-full max-w-screen-2xl m-8  flex justify-center items-start '>

          <div className='w-[70%] h-full max-md:w-full gap-3 flex flex-col justify-center items-start'>
            <p className='text-lg max-md:text-sm text-justify mt-5 text-black'>{content}
            </p>
            {descriptionData.map((item, index) => (
              <div key={index} className=''>
                <h1 className='text-lg max-md:text-sm mt-5 text-black font-bold'>{item.title}</h1>
                <p className='text-lg max-md:text-sm text-justify text-black'>{item.subDescription}</p>

                {item.list && item.list.length > 0 && (
                  <ul className='list-disc text-lg max-md:text-sm text-justify text-black ml-5'>
                    {item.list.map((val, listIndex) => (
                      <li key={listIndex}>{val}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>

          <div className='w-[30%] h-full max-md:hidden gap-3 flex flex-col justify-center items-end'>
            {blogdata.map((item) => (
              <div className='card w-5/6 h-auto max-w-sm     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]' key={item.blog}>
                <img src={item.url} alt='pic' className='w-full h-32 max-md:h-52 shadow-lg rounded-2xl object-cover' />
                <div className='content mt-3 ml-2'>
                  <h2 className='text-sm max-lg:text-sm font-bold'>{item.title}</h2>
                  <p className='text-xs  mt-2 text-justify'>{item.description}</p>
                  {/* <span className={`long-text text-xs  h-0 transition overflow-hidden inline-block  ${collapse === 1 ? "expanded h-14 text-sm" : ""}`}> Management System (LMS). By integrating an LMS into your organization,</span> */}

                  <hr className='mt-3' />
                </div>
                <div className='text-center mt-5'>
                  <NavLink to={item.readMore}> <button type='submit' className='text-center text-xs text-black w-28  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 '/* onClick={() => setCollapse(prev => prev === 1 ? null : 1)}*/ >Read More</button></NavLink>
                </div>
              </div>
            ))}

            <div className='w-full max-md:hidden h-full gap-10 mt-8 flex flex-col justify-center items-center'>
              <p className='w-  text-2xl max-lg:text-xl max-md:text-lg max-sm:text-sm  underline font-bold text-black'>Categories</p>

              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/' >  <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>E-Learning Platform</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/learningculture' >  <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Online Learning</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/waystoBoost' > <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Corporate Training Software</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/' >  <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Cloud-Based LMS</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/future-of-learning' > <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>LMS for Education</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/waystoBoost' >  <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Employee Training System</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/future-of-learning' > <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Mobile Learning</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/learningculture' >  <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>Digital Learning Tools</p></NavLink>
              </div>
              <div className='w-[70%] flex justify-center items-center border-2 rounded-xl bg-slate-400 border-gray-500'>
                <NavLink to='/' > <p className='text-lg max-lg:text-sm cursor-pointer font-semibold text-black'>SCORM-Compliant LMS</p></NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoostEmpBlogB
