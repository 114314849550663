import React from 'react'
// import imgA from '../../assets/Rectangle 52.png'

function AboutB({ data }) {
    const title = data && data[1].title;
    const List = data && data[1].List;
    const url = data && data[1].url;
    return (
        <div>
            <div className='w-full h-full flex justify-center overflow-hidden m-0 p-5 items-center bg-[#E3E9FF]'>
                <div className='w-full h-full  m-0 flex justify-center items-center '>
                    <div className='w-full   h-full max-[640px]:p-7  max-w-screen-xl max-h-screen-lg  max-sm:-mt-20 max-lg:w-full max-sm:w-full max-md:w-full items-center justify-center flex'>
                        <div className='w-5/6 h-5/6  max-sm:w-12 max-md:w-12 max-lg:w-12   max-lg:hidden'>
                            <img src={url} className='h-[60%] w-[60%] ml-10  border-white object-center rounded-2xl' />
                        </div>

 
                        <div className='w-5/6 h-5/6   max-xs:w-full    justify-start   max-sm:w-full  flex flex-col   align-middle  my-1  text-white text-center'>

                            <div className='w-5/6  h-5/6 max-sm:w-full max-sm:h-full -mt-3  max-lg:mt-14  justify-center items-center  flex-justify'>
                                <h1 className=' flex justify-start  max-lg:text-lg text-2xl items-start text-black '>{title}</h1>
                                <div className='max-md:text-sm max-sm:w-20 max-lg:-ml-1 max-md:-ml-8 max-sm:-ml-1 border-b-4 border-yellow-300 w-20 h-2 ml-12 mr-96 relative  left-24'>
                                </div>
                                {List.length > 0 && (

                                    <ul className='w-auto  h-auto max-lg:text-sm max-md:w-full  mt-2  list-disc text-black text-sm text-justify '>
                                        {List.map((val, listIndex) => (
                                            <li key={listIndex}><span className='font-bold'>{val.bold} : </span> {val.description}</li>
                                        ))}

 
                                    </ul>

                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutB
