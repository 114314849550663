import React, { useState } from "react";
import BoostEmpBlogA from "./BoostEmpBlogA";
import BoostEmpBlogB from "./BoostEmpBlogB";
import BlogData from "../blogs.json";

function WaysToBoostEmpBlog() {
  const [data, setData] = useState(BlogData);
  return (
    <div>
      <BoostEmpBlogA data={data} />
      <BoostEmpBlogB data={data} />
      {/* <BoostEmpBlogC data={data}/>  */}
    </div>
  );
}

export default WaysToBoostEmpBlog;
