import React from 'react'
import img from '../../../assets/BlogBanner2.png'

function BoostEmpBlogA({ data }) {

    console.log(data)

    console.log("img=>", data[1].url);

    return (
        <div className='w-full min-h-full  bg-slate-300 flex items-center justify-center'>
            <div className='w-full max-w-screen-2xl h-full flex justify-center items-center'>
                <img src={data[1].url} alt='' className='w-full h-full mt-16 object-cover rounded-lg' />
            </div>
        </div>

    )
}

export default BoostEmpBlogA
