import React from 'react'
// import pic from '../../assets/home-D.png'
import imgA from '../../assets/rtc1.png'
import imgB from '../../assets/rct2.png'
import imgC from '../../assets/rct3.png'
import imgD from '../../assets/rct4.png'
import imgE from '../../assets/rct5.png'
import imgF from '../../assets/rct6.png'
import imgG from '../../assets/rct7.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

function HomeCompoD({data}) {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='w-full h-full flex justify-center items-center p-8'>
                <div className='w-5/6  h-5/6 flex flex-col   items-center justify-center gap-7'>

                    <div className='text-2xl mt-4 font-bold text-black max-md:text-xl'>
                        <p>{data.heading}</p>
                    </div>

                    <div className='w-full h-full max-w-6xl max-lg:hidden flex flex-col mt-4 gap-5 justify-center items-center rounded-lg bg-[#8EA4F5]'>
                        <div className=' w-5/6 max-md:w-full h-full mt-5 gap- flex justify-center items-center'>
            
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgA} alt='' className='w-full h-full object-cover rounded-xl' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[0].title}</p>
                            </div>
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgB} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[1].title} </p>
                            </div>
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgC} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[2].title}</p>
                            </div>
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgD} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[3].title}</p>
                            </div>


                        </div>

                        <div className=' w-4/6 max-md:w-5/6 h-full mb-5 gap- flex justify-center items-center'>
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgE} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[4].title}</p>
                            </div>

                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgF} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[5].title} </p>
                            </div>
                            <div className='w-1/3 flex flex-col justify-center items-center'>
                                <img src={imgG} alt='' className='w-full h-full object-cover rounded-lg' />
                                <p className='text-sm max-lg:text-xs font-semibold'>{data.gallery[6].title}</p>
                            </div>


                        </div>

                    </div>


                

                    <div className='w-5/6 h-5/6 max-sm:w-full hidden max-lg:block mb-5 max-sm:rounded-lg '>
                        <Slider {...settings} >
                            <div className='card1   flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4'>
                                <div className='w-full h-full rounded-lg'>
                                    <img src={imgA} alt='pic' className='w-full h-2/3 rounded-lg object-cover' />

                                    <p className='text-lg text-center'>Tailored Learning Experience

                                    </p>
                                </div>
                               
                            </div>
                            <div className='card2  flex-none max-sm:rounded-lg w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4 '>
                                <div className='w-full h-full rounded-lg'>
                                    <img src={imgB} alt='pic' className='w-full h-2/3 rounded-lg object-cover' />

                                    <p className='text-lg text-center'>Easy To use Interface

                                    </p>
                                </div>
                                
                            </div>
                            <div className='card3  flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4 '>
                                <div className='w-full h-full rounded-lg'>
                                    <img src={imgC} alt='pic' className='w-full h-4/6 rounded-md object-cover' />

                                    <p className='text-lg text-center'>Easy Integration

                                    </p>
                                </div>
                               
                            </div>
                            <div className='card4  flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4 '>
                                <div className='w-full h-full'>
                                    <img src={imgD} alt='pic' className='w-full h-2/3 rounded-md object-cover' />

                                    <p className='text-lg text-center'>Scalability

                                    </p>
                                </div>
                            
                            </div>
                            <div className='card5  flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px]  bg-white p-4 '>
                                <div className='w-full h-full'>
                                    <img src={imgE} alt='pic' className='w-full h-2/3 rounded-md object-cover' />

                                    <p className='text-lg text-center'>Advanced Analytics & Reporting

                                    </p>
                                </div>
                                
                            </div>
                            <div className='card6  flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4'>
                                <div className='w-full h-full'>
                                    <img src={imgF} alt='pic' className='w-full h-2/3 rounded-md object-cover' />

                                    <p className='text-lg text-center'>Support

                                    </p>
                                </div>
                               
                            </div>
                            <div className='card7  flex-none max-sm:rounded-lg  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-80 h-[380px] bg-white p-4'>
                                <div className='w-full h-full'>
                                    <img src={imgG} alt='pic' className='w-full h-2/3 rounded-md object-cover' />

                                    <p className='text-lg text-center'>Flexible Pricing

                                    </p>
                                </div>
                                
                            </div>

                        </Slider>
                    </div>





                </div>
            </div>
        </>
    )
}

export default HomeCompoD
