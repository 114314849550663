import React, { useState } from 'react'
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from './Sidebar';
import { NavLink } from 'react-router-dom';
import pic from '../assets/MAPLE.svg';

function Header() {

  

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className='header w-full h-full'>
      {isMenuOpen && (
        <Sidebar onClose={() => setIsMenuOpen(false)} />
      )}

      <div className='w-full h-full  pl-8 pr-8 max-md:text-xs flex justify-between items-center bg-gray-400  bg-opacity-80 '>
      <div className=''>
      <NavLink to='/'> <img src={pic} alt='pic' className='w-44' /></NavLink>
        </div>
        <div className=''>
          <GiHamburgerMenu
            className='text-2xl hidden max-md:block cursor-pointer'
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>

        

        <nav className='space-x-8 max-[836px]:space-x-4  max-md:hidden max-md:ml-5 text-black cursor-pointer font-semibold text-center max-lg:mr-10 mt-2 h-12'>
          <NavLink to='/'><span className='hover:text-white'>Home</span></NavLink>
          {/* <NavLink to='/curriculam'><span className='hover:text-white'>Curriculam</span></NavLink> */}
          {/* <NavLink to='/testimonial'><span className='hover:text-white'>Testimonial</span></NavLink> */}
          <NavLink to='/blogHome'><span className='hover:text-white'>Blog</span></NavLink>
          <NavLink to='/about'> <span className='hover:text-white'>   About Us </span></NavLink>
          <NavLink to='/contact'> <span className='hover:text-white'>   Contact Us </span></NavLink>
          {/* <button className='bg-yellow-300 w-36 max-[836px]:w-28 max-md:text-xs p-1 text-center rounded-md text-black hover:text-white'>
            Start Learning
          </button> */}
        </nav>
      </div>
    </nav>
  )
}

export default Header
