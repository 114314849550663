import React from 'react'
import { MdOutlineWatchLater } from 'react-icons/md'
import { MdSlowMotionVideo } from 'react-icons/md'
import { MdGroup } from 'react-icons/md'
import start from '../../assets/star.png'
import imgA from '../../assets/curriA.png'
import imgB from '../../assets/curriB.png'
import imgC from '../../assets/curriC.png'
import imgD from '../../assets/curriD.png'
import imgE from '../../assets/curriE.png'
import imgF from '../../assets/curriF.png'
import imgG from '../../assets/curriG.png'
import imgH from '../../assets/curriH.png'
import imgI from '../../assets/curriI.png'

function CurriculamA() {
  return (
    <div>
      <div className='w-full h-full flex justify-center max-md:w-full p-0 m-0 bg-[#E3E9FF] '>
        <div className='img max-w-screen-xl max-md:w-5/6 max-sm:w-full max-md:h-full max-h-screen-lg  w-5/6 h-4/5 p-5 mt-10 grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 gap-5 mb-5'>
          <div className='card1 w-full max-w-lg max-lg:flex-none     max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgA} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.9</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='text-white text-xs ml-3' />
                <p className='text-xs max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='w-5 h-5  text-xs text-white' />
                <p className='text-white text-xs max-lg:text-xs font-semibold'>32  Video</p>
              </div>
              <div className='flex justify-center items-center text-xs max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white text-xs' />
                <p className='text-xs max-lg:text-xs font-semibold text-white mr-5'>930 Siswa</p>
              </div>
            </div>
          </div>


          <div className='card2 w-full max-w-lg max-lg:flex-none     max-lg:h-auto   rounded-lg bg-[#3D52A0]'>
            <div>
              <img src={imgB} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.9</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='text-white text-xs ml-3' />
                <p className='text-xs max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='w-5 h-5  text-xs text-white' />
                <p className='text-white text-xs max-lg:text-xs font-semibold'>32  Video</p>
              </div>
              <div className='flex justify-center items-center text-xs max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white text-xs' />
                <p className='text-xs max-lg:text-xs font-semibold text-white mr-5'>930 Siswa</p>
              </div>
            </div>
          </div>

          <div className='card3 w-full max-w-lg max-lg:flex-none   max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgC} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='text-white  text-xs ml-3' />
                <p className='text-xs max-lg:text-xs font-semiboldtext-white text-white '>4,5 Jan</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='w-5 h-5  text-xs text-white' />
                <p className='text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5  text-xs text-white' />
                <p className='text-xs max-lg:text-xs font-semibold text-white mr-5'>20 Video</p>
              </div>
            </div>
          </div>
          <div className='card4 w-full max-w-lg  max-lg:flex-none     max-lg:h-auto   rounded-lg bg-[#3D52A0]'>
            <div>
              <img src={imgD} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='w-5 h-5  text-xs text-white' />
                <p className='text-sm max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex text-xs justify-center items-center max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
            </div>
          </div>
          <div className='card5 w-full  max-w-lg max-lg:flex-none    max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgE} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='ml-3  text-xs text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5  text-xs text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white mr-5'>1.900 Siswa</p>
              </div>
            </div>
          </div>
          <div className='card6 w-full max-w-lg max-lg:flex-none      max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgF} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className=' text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className=' text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5 flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='ml-3  text-xs text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white '>4,5 Jan</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold mr-5 text-white'>1.900 Siswa</p>
              </div>
            </div>
          </div>
          <div className='card7 w-full max-w-lg max-lg:flex-none      max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgG} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className=' text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className=' text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5 flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='ml-3  text-xs text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white '>4,5 Jan</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold mr-5 text-white'>1.900 Siswa</p>
              </div>
            </div>
          </div>
          <div className='card8 w-full max-w-lg max-lg:flex-none      max-lg:h-auto   rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgH} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>
            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='ml-3  text-xs text-white' />
                <p className='text-sm max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='text-white  text-xs ' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5  text-xs text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold mr-5 text-white'>1.900 Siswa</p>
              </div>
            </div>
          </div>
          <div className='card9  w-full max-w-lg max-lg:flex-none     max-lg:h-auto  rounded-lg  bg-[#3D52A0]'>
            <div>
              <img src={imgI} alt='pic' className='w-full h-40 max-md:h-52 shadow- rounded- object-cover' />
            </div>
            <div className='w-full flex justify-end items-center -mt-3 '>
              <div className='bg-white mr-2 rounded-xl text-sm flex justify-center items-center gap-1 text-black w-20'>
                <img src={start} alt='pic' className='w-5 h-5' />
                <p className='text-xs'>4.4</p>
              </div>
            </div>
            <div className='content mt-3 ml-2'>
              <h2 className='text-base max-lg:text-sm font-bold text-white'>Dasar Pemrograman WEB </h2>
              <p className='text-xs max-lg:text-xs mt-2 text-white'>Materi pembelajarn mengenai pembuatan website tingkat pemula
              </p>

            </div>
            <div className='w-full h-10 mt-5  flex justify-between'>
              <div className='flex justify-center items-center gap-1'>
                <MdOutlineWatchLater className='ml-3 text-white' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>4,5 Jan</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdSlowMotionVideo className='text-white  text-xs' />
                <p className=' text-xs max-lg:text-xs font-semibold text-white'>20 Video</p>
              </div>
              <div className='flex justify-center items-center text-sm max-lg:text-xs   gap-1'>
                <MdGroup className='w-5 h-5 text-white  text-xs' />
                <p className='text-xs max-lg:text-xs font-semibold mr-5 text-white'>1.900 Siswa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurriculamA
