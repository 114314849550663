import React, { useState } from 'react'
import pic from '../../assets/home-E.png'

function HomeCompoF({ data }) {
    const [collapse, setCollapse] = useState(false);
    return (
        <div className='w-full h-full m-0 p-0 flex items-center justify-center '>
            <div className='w-full  h-5/6 p-5 max-w-screen-xl max-h-screen-lg flex items-center justify-center'>
                <div className='w-full h-full  flex gap-8  items-center flex-col '>
                    <div className='w-5/6 bg-[#8EA4F5] rounded-3xl p-3 h-full  flex flex-col text-justify justify-center items-center'>
                        <h2 className='text-3xl max-md:text-lg max-sm:border-b-4 max-sm:border-b-yellow-500  font-bold'>{data.heading}</h2>
                        <div className='max-md:text-sm text-sm mt-2 border-b-4 border-yellow-600 w-32 max-lg:w-20 max-md:left-40 max-sm:hidden relative left-28 '></div>
                        <p className='text-sm max-md:text-xs w-4/6 mb-3 max-sm:w-full mt-3  '>{data.description} </p>
                    </div>

                    <div className='w-5/6 max-sm:w-full mt-5  flex justify-center '>

                        <div className='content w-full  h-full   mt-4 ml-3'>
                            <h2 className='text-2xl max-xl:text-lg max-md:-mt-10 font-semibold items-center '>{data.title}</h2>
                            <p className='mt-4 text-sm text-justify max-lg:text-xs'><span className='font-semibold'>Client : </span> {data.client} <br /><span className='font-semibold'>Challenge: </span> {data.challenge}<br />
                            <span className='font-semibold'>Solution:</span> {data.solution}

                                <span className={`long-text text-sm max-lg:text-xs h-0 transition list-disc overflow-hidden inline-block  ${collapse ? "expanded h-40" : ""}`}>
                                <span className='font-semibold'>Results:</span>
                                    <ul className=' ml-5 mr-5  list-disc text-justify'>
                                        {data.result && data.result.map((val, index) => (
                                            <li key={index}>
                                                {val}
                                            </li>
                                        ))}
                                    </ul>
                                </span>

                            </p>
                            <div className='w-full flex justify-between items-center'>
                                <button type='submit' className='text-center text-xs mt-5 mb-10 text-black w-32 max-md:w-20 bg-yellow-600  rounded-md font-semibold  py-2 ' onClick={() => setCollapse((prev) => !prev)} >Read More</button>
                                {/* <button type='submit' className='text-center text-xs mt-5 mr-5 mb-10 text-black w-32 max-md:w-20 bg-yellow-600  rounded-md font-semibold  py-2 '  >Next -></button> */}
                            </div>
                        </div>


                        <div className='image w-full h-full max-md:hidden flex flex-col mt-  justify-center items-center p-4 '>
                            <div className='w-5/6 h-5/6  '>
                                <img src={pic} alt='pic' className='w-full max-w-full h-auto relative  z-10 border-8 border-slate-600 rounded-xl object-cover' />
                                <div className='w-20 h-20 bg-yellow-500 z-0 relative rounded-full -translate-x-11 -translate-y-11 opacity-40'></div>

                            </div>


                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default HomeCompoF
