import React from 'react'
import img from '../../../assets/BlogBanner3.png'

function LearningCultureA({ data }) {
    return (
        <div className='w-full h-full flex justify-center items-center'>
            <div className='w-full max-w-screen-2xl h-full flex justify-center items-center'>
                <img src={data[2].url} alt='' className='w-full h-full mt-16 object-cover rounded-lg' />
            </div>
        </div>
    )
}

export default LearningCultureA
