import React, { useState } from 'react'
import imgA from '../../assets/Group 9.png'
import imgC from '../../assets/Group 10.png'
import imgB from '../../assets/Group 11.png'

function HomeCompoE({data}) {

    const [collapse, setCollapse] = useState(false);

    return (
        <div>
            <div className='w-full h-full flex justify-center overflow-hidden m-0  items-center bg-[#3D52A0]'>
                <div className='w-full h-full  m-0 flex justify-center items-center '>
                    <div className='w-5/6 h-full max-w-screen-xl max-h-screen-lg gap-5 max-xl:gap-20   max-sm:-mt-20 max-lg:w-full max-sm:w-full max-md:w-full items-center justify-center flex'>
                        <div className='w-[50%] max-[1006px]:ml-8 max-[918px]:-mr-4   h-full max-w-xl -mt-5 max-md:hidden    max-h-lg flex flex-col justify-center  items-center'>

                            <div className='w-48 h-44 z-10 rounded-lg translate-y-16  max-md:w-full flex flex-col -translate-x-20   relative  justify-center  items-center bg-white'>
                                <div className='w-16 mt-3 h-20 max-md:w-full max-md:hideen  flex flex-col justify-center rounded-lg items-center'>
                                    <img src={imgA} alt='' className='h-15 w-20 relative  z-20 rounded-lg' />

                                </div>

                                <div className='w-44 h-28 mb-3 text-black flex flex-col justify-center items-center'>
                                    <h1 className='text-sm mt-1 text-center font-bold '>{data.list[0].title} </h1>
                                    <p className='text-xs mt-2 text-center'>{data.list[0].description}</p>
                                </div>
                            </div>
                            <div className=' w-16 h-16 relative z-0 translate-y-20 -translate-x-28 bg-gray-200 opacity-30 rounded-full -ml-8 -mt-14 '></div>


                            <div className='w-48 h-44 rounded-lg max-md:w-full relative flex flex-col translate-x-40  z-10 justify-center items-center  bg-white'>
                                <div className='w-20 mt-1 h-20 max-md:w-full flex flex-col justify-center rounded-lg items-center'>
                                    <img src={imgB} alt='' className='h-12 w-12 mt-3  rounded-lg' />
                                </div>
                                <div className='w-44 h-28 mb-4 max-md:w-full text-black flex flex-col justify-center items-center'>
                                    <h1 className='text-sm mt-1 text-center max-md:w-full font-bold'>{data.list[1].title}</h1>
                                    <p className='text-xs mt-2 text-center '>{data.list[1].description} </p>
                                </div>
                            </div> 
                            <div className=' w-12 h-12 relative z-0 translate-y-20 translate-x-32 bg-gray-200 opacity-30 rounded-full -ml-8 -mt-14 '></div>
                            <div className=' w-16 h-16 relative z-0 translate-y-8  translate-x-60 bg-blue-300 opacity-50 rounded-full -ml-8 -mt-14 '></div>


                            <div className='w-48 h-44 rounded-lg  flex flex-col max-md:w-full relative -translate-x-16 -translate-y-16   justify-center items-center   bg-white'>
                                <div className='w-20 mt-2 h-20 max-md:w-full flex flex-col justify-center rounded-lg items-center'>
                                    <img src={imgC} alt='' className='h-15 w-14 mt-2 rounded-lg' />
                                </div>
                                <div className='w-44 h-28 mb-3 text-black flex flex-col max-md:w-full justify-center items-center'>
                                    <h1 className='text-sm mt-1 text-center max-md:w-full font-bold'> {data.list[2].title}</h1>
                                    <p className='text-xs mt-2 text-center'>{data.list[2].description} </p>
                                </div>
                            </div>

                        </div>

                        <div className='w-[50%] h-full max-md:w-full max-w-lg  max-lg:ml-20 max-sm:mb-5 max-xl:ml-20 justify-start max-md:items-start  flex flex-col       text-white '>

                            <div className=' w-5/6 h-full   justify-center items-start  max-sm:mt-32 flex flex-col '>
                                
                                <h1 className='text-white text-3xl max-md:text-lg font-semibold max-md:hidden border-b-4 border-indigo-600'>
                                {data.heading}
                                </h1>
                                <h1 className='text-white hidden max-md:block text-3xl max-md:text-lg w-full font-semibold underline'>
                                {data.heading}
                                </h1>

                                <p className='text-white mt-3 text-justify max-md:text-sm text-md font-thin'>
                                   {data.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeCompoE
