import React, { useState } from 'react'
// import imgA from '../../assets/testiCard1.png';
import imgB from '../../assets/testiCard2.png';
import imgC from '../../assets/testiCard3.png';
import imgD from '../../assets/testiCard4.png';
import imgE from '../../assets/testicard5.png';
import imgF from '../../assets/testiCard6.png';
import imgG from '../../assets/testiCard7.png';
import imgH from '../../assets/testiCard8.png';
import imgI from '../../assets/testiCard9.png';

function TestimonialB() {
    const [collapse, setCollapse] = useState(false);
    return (
        <div className=' w-full h-full m-0 p-0 flex justify-center items-center'>

            <div className='w-5/6 h-full max-w-screen-2xl grid grid-cols-3 justify-center items-center  max-lg:grid-cols-2 max-md:grid-cols-1 gap-5 mb-5 '>

                <div className='card1 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgG} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 1 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 1 ? null : 1)} >Read More</button>
                    </div>



                </div>
                <div className='card2 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgB} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 2 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 2 ? null : 2)} >Read More</button>
                    </div>



                </div>
                <div className='card3 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgC} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 3 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 3 ? null : 3)} >Read More</button>
                    </div>



                </div>
                <div className='card4 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgD} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 4 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 4 ? null : 4)} >Read More</button>
                    </div>



                </div>
                <div className='card5 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgE} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 5 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 5 ? null : 5)} >Read More</button>
                    </div>



                </div>
                <div className='card6 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgF} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 6 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 6 ? null : 6)} >Read More</button>
                    </div>



                </div>
                <div className='card7 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgG} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 7 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 7 ? null : 7)} >Read More</button>
                    </div>



                </div>
                <div className='card8 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgH} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 8 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 8 ? null : 8)} >Read More</button>
                    </div>



                </div>
                <div className='card9 w-full max-w-lg     max-lg:h-auto rounded-lg  p-2 bg-[#8EA4F5]'>
                    <img src={imgI} alt='pic' className='w-full h-40 max-md:h-52 shadow-lg rounded-xl object-cover' />
                    <div className='content mt-3 ml-2'>
                        <h2 className='text-lg max-lg:text-sm font-bold'>Lorem Ipsum is</h2>
                        <p className='text-sm max-lg:text-xs mt-2'>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimen.
                            <span className={`long-text text-sm max-lg:text-xs h-0 transition overflow-hidden inline-block  ${collapse === 9 ? "expanded h-16 text-sm" : ""}`}>Lorem Ipsum is simply  text of the printing and type setting industry. make a type specimenLorem Ipsum is
                                simply  text of the printing and type setting industry. make a type specimen</span>
                        </p>
                        <hr className='' />
                    </div>
                    <div className='text-center mt-5'>
                        <button type='submit' className='text-center text-xs  mb-5 text-black w-40  bg-yellow-500 hover:text-slate-500  rounded-md font-semibold  py-2 ' onClick={() => setCollapse(prev => prev === 9 ? null : 9)} >Read More</button>
                    </div>



                </div>


            </div>
        </div>
    )
}

export default TestimonialB
