import React from 'react'
import TestimonialA from './TestimonialA'
import TestimonialB from './TestimonialB'

function Testimonial() {
  return (
    <div className=' w-full h-full bg-indigo-100'>

        <TestimonialA />
        <TestimonialB />
      
    </div>
  )
}

export default Testimonial
