import React from 'react'
// import imgA from '../../assets/1.png'
import imgB from '../../assets/carosuel1f.jpg'
import imgC from '../../assets/carosuel2f.jpg'
import imgD from '../../assets/carousel3f.jpg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

function HomeCompoH() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>

      <div className='w-full h-full bg-[#3D52A0]  m-0 p-0 flex justify-center items-center'>
        <div className='w-full h-5/6 max-w-screen-xl gap-10 flex flex-col justify-start items-center'>
          <div className='heading text-3xl mt-10 max-md:text-lg font-bold border-b-4 border-indigo-600'>
            <p className='text-white'>Why Maple Learn?</p>
          </div>

          <div className='w-4/6 h-5/6 mb-5 max-sm:rounded-md '>
            <Slider {...settings} >
              <div className='card1   flex-none max-sm:rounded-md  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-96 max-[770px]:h-60 max-[445px]:h-40 h-[380px]  p-4'>
                <div className='w-full h-full'>
                  <img src={imgC} alt='pic' className='w-full h-full rounded-md  object-fill' />
                </div> 
              </div>
              <div className='card2  flex-none max-sm:rounded-md w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-96 max-[770px]:h-60 max-[445px]:h-40 h-[380px]  p-4'>
                <div className='w-full h-full'>
                  <img src={imgD} alt='pic' className='w-full h-full rounded-md' />
                </div>
              </div>
              <div className='card3  flex-none max-sm:rounded-md  w-[350px] max-lg:w-1/2 max-sm:w-full max-lg:h-96 max-[770px]:h-60 max-[445px]:h-40 h-[380px]  p-4'>
                <div className='w-full h-full'>
                  <img src={imgB} alt='pic' className='w-full h-full rounded-md' />
                </div>
              </div>

            </Slider>
          </div>
        </div>
      </div>
    </div>

  )
}

export default HomeCompoH
