import React from 'react'
import img from '../../assets/Rectangle 52 (1).png'

function AboutA({ data }) {
    const content = data && data[0].content;
    const url = data && data[0].url;
    const descriptionData = data && data[0].description;
    const button1 = data && data[0].btn1;
    const button2 = data && data[0].btn2;

    return (
        <div>
            <div className='w-full h-full flex justify-center overflow-hidden m-0  items-center bg-white'>
                <div className='w-full h-full  mt-24 flex justify-center items-center '>

                    <div className='w-full h-full  max-md:mt-10 gap-8 max-[640px]:p-5 max-w-screen-xl max-h-screen-lg  max-sm:-mt-16 max-lg:w-full max-sm:w-full max-md:w-full items-center justify-center flex'>
                        <div className='w-[50%] h-full max-md:w-full max-[1024px]:w-5/6 max-[1024px]:mb-3 max-[1300px]:p-4  max-md:p-0   max-sm:w-full max-sm:h-full  flex flex-col gap-10 justify-center items-center text-black text-center'>
                            <div className='w-full h-full max-xs:w-full max-[767px]:p-3  max-sm:w-full max-sm:mt-14 max-xs:h-full max-lg:text-sm max-sm:text-xs  flex  justify-center items-center'>
                                <div className=" text-xs max-lg:text-sm  max-md:w-full max-sm:w-full text-justify text-black">{content}
                                    {descriptionData.map((item, index) => (
                                        <div key={index}>
                                            <p className='max-md:w-full text-black text-lg mt-2'>{item.title}</p>
                                            <p className="max-md:w-full text-xs max-lg:text-sm  text-justify text-black">{item.subdescription}</p>

                                        </div>
                                    ))}


                                </div>
                            </div>
                        </div>



                        <div className='w-[50%] h-[70%] max-md:p-3 max-sm:w-16 max-sm:h-16 max-lg:h-16 max-lg:w-12  max-md:hidden   max-lg:hidden  '>
                            <img src={url} className='w-5/6  h-4/6 max-sm:w-12 max-md:w-12 max-lg:w-12 relative rounded-lg  z-20   ' />
                            <div className=' w-16 h-16 relative z-10 bg-gray-500 rounded-full -ml-5  -mt-10 '></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutA
