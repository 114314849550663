import React, { useState } from 'react'

function CurriculamHeader() {
  
  return (
    <div>
      <div className='w-auto h-full flex flex-col justify-center items-center  max-md:w-full p-0 m-0 bg-blue-100'>
        <div className=' w-full mt-20 max-md:mt-20 max-w-6xl  h-full   flex   justify-center items-center '>
          <input type='text' id='text' className='w-3/6 max-md:hidden px- py-2 pl-10 bg-blue-100  border-2 rounded-md border-black' placeholder='Search Course' />
          <div class=" w-5/6 h-full mt-5 bg-indigo-100 max-md:w-3/6  hidden max-md:block relative ">
            <select class=" appearance-none hidden max-md:block max-lg:block w-full bg-indigo-100 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
              <option>Select Your Option</option>
              <option>All</option>
              <option>UI design</option>
              <option>Programing</option>
              <option>Marketing</option>
              <option>Soft skill</option>
              <option>Network</option>
              <option>Data Analytics</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 max-md:block flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
            </div>
          </div>
        </div>

        <div className='w-auto h- flex mt-5 max-md:w-5/6 justify-center max-md:justify-start  max-md:-translate- max-md:items-start'>

          <div className=' flex  max-sm:gap-2   max-md:hidden text-xs gap-12 hover:cursor-pointer '>
            <p className=' hover:text-blue-900 text-sm'>All</p>
            <p className=' hover:text-indigo-800 text-sm'>UI disign</p>
            <p className='hover:text-indigo-800 text-sm'>Programing</p>
            <p className='hover:text-indigo-800 text-sm'>Marketing </p>
            <p className='hover:text-indigo-800 text-sm '>Soft skill</p>
            <p className=' hover:text-indigo-800 text-sm'>Network</p>
            <p className=' hover:text-indigo-800 text-sm'>Data Analytics</p>
          </div>
        </div>

      </div>


    </div>
  )
}

export default CurriculamHeader
