import React from 'react'
import CurriculamHeader from './CurriculamHeader'
import CurriculamA from './CurriculamA'


function Curriculam() {
  return (
    <div>
      <CurriculamHeader />
      <CurriculamA />
      
    </div>
  )
}

export default Curriculam
