import React, { useState } from "react";
import FutureOfLearningA from "./FutureOfLearningA";
import FutureOfLearningB from "./FutureOfLearningB";
import BlogData from "../blogs.json";

function FutureOfLearningBlog() {
  const [data, setData] = useState(BlogData);
  console.log(data);

  return (
    <div>
      <FutureOfLearningA data={data} />
      <FutureOfLearningB data={data} />
      {/* <FutureOfLearningC data={data}/> */}
    </div>
  );
}

export default FutureOfLearningBlog;
